import * as THREE from "three";
import * as TWEEN from "tween.js";
import Experience from "../Experience.js";

export default class redPulses {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene

        this.cylinders = new Map
        this.rings = new Map

    }

    ringGeometry = () => {
        const ringGeometry = new THREE.RingGeometry(0.4, 0.9, 32);
        const ringMaterial = new THREE.MeshBasicMaterial({
            color: 0xdc2626,
            side: THREE.DoubleSide,
            opacity: 0.5,
            transparent: true,
        });
        const ring = new THREE.Mesh(ringGeometry, ringMaterial);
        ring.scale.set(0.5, 0.5, 0.5);
        ring.rotation.x = Math.PI / 2;

        return ring;
    }
    cylinderGeometry = () => {
        const geometry = new THREE.CylinderGeometry(0.1, 0.1, 0.1);
        const material = new THREE.MeshBasicMaterial({
            color: 0xdc2626,
            opacity: 0.5,
            transparent: true,
        });
        const cube = new THREE.Mesh(geometry, material);
        //cube.rotation.x = Math.PI / 2;

        return cube;
    }

    createPulsatingEffect = () => {
        // This changes the scale of the ring and creates the expanding pulse.
        const tween1 = new TWEEN.Tween(this.ring.scale)
            .to(new THREE.Vector3(1, 1, 1))
            .easing(TWEEN.Easing.Quadratic.Out)
            .repeat(Infinity)
                .start()
                //.delay(10000)
            ;

        // This changes the opacity which makes it show and fade away.
        const tween2 = new TWEEN.Tween(this.ring.material)
            .to({ opacity: 0 })
            .easing(TWEEN.Easing.Quadratic.Out)
            .repeat(Infinity)
                .start()
                //.delay(10000)
            ;

        //tween1.stop()
        //tween2.stop()
        //tween1.start()
    }
    add = (polygon) => {
        const position = polygon.position

        this.cylinder = this.cylinderGeometry();
        this.ring = this.ringGeometry();

        this.cylinder.position.set(position.x, position.y, position.z)
        this.ring.position.set(position.x, position.y, position.z)

        this.cylinders.set(this.cylinder.uuid, this.cylinder)
        this.rings.set(this.ring.uuid, this.ring)

        this.createPulsatingEffect()

        this.scene.add(this.cylinder, this.ring);
    }

    removeAllRings = () => {
        this.cylinders.forEach((row)=>{
            this.scene.remove( row );
        })
        this.rings.forEach((row)=>{
            this.scene.remove( row );
        })
    }
}
