
import * as THREE from 'three';
import { v4 as uuidv4 } from 'uuid';

import Experience from '../Experience.js'
import _ from 'lodash'


export default class HtmlHelper
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.camera = this.experience.camera
    this.debug = this.experience.debug

    this.polygons = new Map;

    this.svg = new Map;

    this.svgs();

    this.screenMarkers = new Map;
  }

  svgs = () => {
    this.svg.set('point', '<svg width="26.8" height="15" viewBox="0 0 518 365" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_106_395)"><circle cx="259" cy="183" r="140" fill="white"/></g><circle cx="259" cy="183" r="85" fill="black"/></svg>')
    this.svg.set('marker','<svg viewBox="0 0 36.8 50" width="36.8" height="50" data-testid="location-pin" display="block"><g><path d="M18.4,48.3c-1.2,0-2.2-0.7-2.6-1.8c-0.5-1.3-0.9-2.5-1.3-3.5v-0.1c-0.4-1.2-0.9-2.5-1.4-3.6 c-1.3-2.8-3.3-5.7-6.8-9.6l-0.1-0.1c-2.8-3-4.4-7.1-4.4-11.2c0-9.2,7.5-16.6,16.6-16.6c0.6,0,1.3,0,1.9,0.1 c9.1,1,15.6,9.1,14.7,18.2c-0.4,3.6-1.9,6.9-4.3,9.5c-3.5,3.9-5.6,6.8-6.9,9.7c-0.5,1.2-1,2.3-1.4,3.5L22.3,43 c-0.4,1.1-0.7,2.2-1.3,3.5C20.6,47.6,19.5,48.3,18.4,48.3z" fill="#E94540"></path><path d="M18.4,3.5L18.4,3.5c0.6,0,1.1,0,1.7,0.1c8.1,0.9,14,8.1,13.1,16.3c-0.3,3.2-1.7,6.2-3.9,8.5l0,0l0,0 c-3.6,4-5.8,7.1-7.2,10.1c-0.5,1.2-1,2.4-1.4,3.7l-0.1,0.2c-0.3,1-0.7,2.1-1.2,3.4c-0.3,0.6-0.9,0.8-1.5,0.5 c-0.2-0.1-0.4-0.3-0.5-0.5c-0.5-1.3-0.9-2.4-1.2-3.4v-0.1c-0.4-1.3-0.9-2.6-1.5-3.8c-1.4-3-3.5-6-7-9.9l0,0l-0.2-0.2 C2,22.4,2.3,13,8.3,7.5c2.8-2.6,6.4-4,10.2-4 M18.6,0C8.4-0.1,0.1,8,0,18.2c0,0.1,0,0.1,0,0.2c0,4.6,1.8,9.1,4.9,12.4l0,0 c3.4,3.8,5.3,6.5,6.6,9.2c0.5,1.1,0.9,2.3,1.3,3.5v0.1c0.3,1.1,0.8,2.2,1.3,3.6c1,2.3,3.7,3.5,6,2.5c1.1-0.5,2-1.4,2.5-2.5 c0.6-1.3,1-2.5,1.3-3.6l0.1-0.2c0.4-1.2,0.8-2.3,1.3-3.4c1.2-2.7,3.2-5.5,6.6-9.2c6.9-7.4,6.4-19-1-25.9C28,2.2,24.4,0.6,20.5,0.1 C19.8,0.1,19.1,0,18.4,0L18.6,0z" fill="white"></path><path d="M25,18.4c0,3.6-2.9,6.6-6.6,6.6s-6.6-2.9-6.6-6.6s2.9-6.6,6.6-6.6C22,11.9,24.9,14.8,25,18.4z" fill="white"></path></g></svg>')
  }


  pointTitle = (polygon, venue, type = 'point') => {

    let html = '';
    html = '<div id="" class="maxmap-tooltip_marker point">'
    html += this.svg.get(type)
    html += '<div class="maxmap-tooltip_marker_txt_cont" style="max-width: 150px; margin-left: 0px; margin-right: 0px; ">'
    html += '<div>'
    html += '<p title="' + venue.name +'" class="maxmap-tooltip_marker_txt_cont_p">' + venue.name + '</p>'
    html += '</div>'
    html += '</div>'
    html += '</div>'

    const wrapper = document.createElement( 'div' );
    wrapper.className = 'maxmap-tooltip';
    wrapper.id = "point_-" + polygon.userData.name
    wrapper.setAttribute('data-polygon-key', polygon.userData.name)

    wrapper.style['pointer-events'] = 'none';
    wrapper.style.opacity = 1;
    wrapper.style.top = '20px'; //will use for fix zoom
    wrapper.style.left = '0px';
    wrapper.style.backgroundColor = 'transparent';
    wrapper.style.transform = 'translate3d(0px, 0px, 0px)';

    const htmlWr = '<div class="collision_ele_hide">' + html + '</div>'

    wrapper.innerHTML = htmlWr;


    return wrapper
  }

  markerTitle = (polygon, title) => {
    let html = '';
    html = '<div id="" class="maxmap-tooltip_marker marker">'
    html += this.svg.get('marker')
    html += '<div class="maxmap-tooltip_marker_txt_cont">'
    html += '<div>'
    html += '<p title="'+title +'" class="maxmap-tooltip_marker_txt_cont_p">' + title + '</p>'
    html += '</div>'
    html += '</div>'
    html += '</div>'

    const wrapper = document.createElement( 'div' );
    wrapper.className = 'maxmap-tooltip';
    wrapper.id = "marker_-" + polygon.userData.name
    wrapper.setAttribute('data-polygon-key', polygon.userData.name)

    wrapper.style['pointer-events'] = 'none';
    wrapper.style.opacity = 1;
    wrapper.style.top = '0px'; //will use for fix zoom
    wrapper.style.left = '0px';
    wrapper.style.backgroundColor = 'transparent';
    wrapper.style.transform = 'translate3d(0px, 0px, 0px)';

    const htmlWr = '<div class="collision_ele_marker">' + html + '</div>'

    wrapper.innerHTML = htmlWr;

    //wrapper.innerHTML = ''
    this.screenMarkers.set(polygon.id, wrapper)
    return wrapper
  }

  removeMarkerPoint = (polygon) => {

  }

  removeAllMarkerPoint = () => {
    this.screenMarkers.forEach((marker)=>{
      marker.innerHTML = '';
    })
  }


}
