


import ApiStore from './ApiStore'

const apiStore = new ApiStore()

export default {
    apiStore: apiStore
}
