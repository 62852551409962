import * as THREE from 'three';
import * as dat from 'lil-gui'
import * as TWEEN from 'tween.js';
import Experience from '../Experience.js'
import Screen from './Screen'
import '../../scss/styles.scss'

import _ from 'lodash'
import * as bootstrap from 'bootstrap'

import Alert from 'bootstrap/js/dist/alert';

// or, specify which plugins you need:
import { Tooltip, Toast, Popover } from 'bootstrap';


//console.log ('-->Alert', Alert );

export default class PolygonConfigScreen extends Screen {
    constructor() {
        super()

        this.active = this.route === '/polygonConfig'
        if (!this.active)
            return;

        this.collisionEngine = this.experience.collisionEngine

        this.experience.emit.on('onPolygonClicked', this.onPolygonClicked)
        this.experience.emit.on('onPolygonDeselect', this.onPolygonDeselect)

        this.experience.emit.on('modelMapLoaded', this._onModelMapLoaded)

        this.experience.emit.on('controlUpdateStart', this._controlUpdateStart)
        this.experience.emit.on('controlUpdateEnd', this._controlUpdateEnd)

        this.initial()


        this.availableBooths = new Map;
        this.canSelectPolygon = true

    }

    initial = () => {
        this.selectedColor = 0x079A34;
        const self = this
        window.addEventListener('message', function(e) {
            const iframMsg = JSON.parse(e.data);
            if (iframMsg.channel === 'set_reserved_booths') {
                self.reservedBoothsIds = iframMsg.reservedBooths;
                self.onReciveReservedBooths()
            }
            if (iframMsg.channel === 'set_selected_booth') {
                setTimeout(() => {
                    console.log('iframMsg.selectedBooth', iframMsg.selectedBooth)
                    const polygons = this.experience.world.mapModel.polygons;
                    polygons.forEach((node) => {
                        if (iframMsg.selectedBooth === node.name) {
                            self.onPolygonClicked([node])
                            console.log('yes')
                        }
                    })

                }, 100)
            }
        });
    }

    onReciveReservedBooths = () => {
        const polygons = this.experience.world.mapModel.polygons;
        polygons.forEach((node) => {
            if (!_.includes(this.reservedBoothsIds, node.name)) {
                node.material = new THREE.MeshPhysicalMaterial({
                    color: 0xC8C8C8,
                    roughness: 1
                });
                this.availableBooths.set(node.name, node)
            }
        })
    }

    _onModelMapLoaded = () => {
        window.parent.postMessage(JSON.stringify({
            channel: 'map_load_done'
        }), '*');
    }

    _controlUpdateStart = () => {
        this.canSelectPolygon = false
    }

    _controlUpdateEnd = (val) => {
        if (val < 10) {
            this.canSelectPolygon = true
        }

        setTimeout(() => {
            this.canSelectPolygon = true
        }, 20)
    }

    onPolygonClicked = (polygon) => {

        if (!this.canSelectPolygon)
            return;

        polygon = polygon[0]

        if (!this.availableBooths.has(polygon.name))
            return;

        if (this.selectedPolygon === polygon.uuid) {
            this.onPolygonDeselect()
            return;
        }
        this.onPolygonDeselect()

        polygon.material = new THREE.MeshPhysicalMaterial({
            color: this.selectedColor,
            roughness: 1
        });
        this.collisionEngine.addMarkerPoint(polygon, 'تم الاختيار')

        window.parent.postMessage(JSON.stringify({
            channel: 'set_polygon',
            polygon_name: polygon.name
        }), '*');

        this.selectedPolygon = polygon.uuid
    }

    onPolygonDeselect = (polygon) => {
        this.collisionEngine.removeAllMarkerPoint()
        this.selectedPolygon = null
        const polygons = this.experience.world.mapModel.polygons;
        window.parent.postMessage(JSON.stringify({
            channel: 'set_polygon',
            polygon_name: null
        }), '*');
        polygons.forEach((node) => {
            if (!_.includes(this.reservedBoothsIds, node.name)) {
                node.material = new THREE.MeshPhysicalMaterial({
                    color: 0xC8C8C8,
                    roughness: 1
                });
                this.availableBooths.set(node.name, node)
            }
        })
    }
}
