let instance = null
import * as THREE from 'three';
import Experience from '../Experience.js'
import _ from 'lodash'
import PathHelper from './PathHelper'
import connectors from '../../const/connectors'

export default class PathBuilder extends PathHelper {
  constructor(){
    super();

    this.experience = new Experience()
    //this = this.experience.pathHelper
    this.scanedDir = new Map()
    this.scanedPolygons = new Map()
    this.lastBranch = new Map()
  }

  nodeId = (nodeName) => {
    return this.id1(nodeName)
  }


  leaf = async (graph, position, node, edge) => {
    if (this.debug.active) {
      /*this.drawPoint(
          position,
          'blue',
          .1,
          30,
      )*/
    }

    const nerstPolygon = this.getNearstPolygon(position)
    for (const _polygon of nerstPolygon) {
      const distnace = _polygon[0]
      const polygon = _polygon[1]

      if (parseFloat(distnace) > connectors.polygonSensor)
        return graph;

      const polygonId = this.nodeId(polygon.name)
      const leafId = this.nodeId(`${node.name}${edge.name}${polygon.name}${position.x}.${position.y}.${polygon.position.z}`)

      const leafPosition = this.xyzFormat({
        x: position.x,
        y: position.y,
        z: polygon.position.z,
      })

      if (node.position.z > polygon.position.z)
        continue;
      if (polygon.position.z > edge.position.z)
        continue;

     /* if (polygon.position.x < node.position.x)
        continue;*/

      const cross = await this.isPointCanConnect(leafPosition, polygon)
      if (!cross.isPointCanConnect)
        continue;

      if (graph.has(polygonId))
      {
        continue;
      }

      if(node.position.z > polygon.position.z)
        continue;

      if (!graph.has(polygonId)) {
        graph = this.addNodeToGraph(
            graph,
            polygonId,
            'polygon',
            'leaf',
            polygon.name,
            polygon.position,
        )
      }

      if (!graph.has(leafId)) {
        graph = this.addNodeToGraph(
            graph,
            leafId,
            'point',
            'leaf',
            'leaf' + polygon.name,
            leafPosition.position
        )
      }

      graph = await this.connect2Nodes(graph, graph.get(polygonId), graph.get(leafId), 'leaf')


      if (this.lastNode && this.lastNode.id !== leafId && graph.has(leafId) && graph.has(this.lastNode.id))
      {
        graph = await this.connect2Nodes(graph, graph.get(leafId), graph.get(this.lastNode.id), 'leaf')
      }
      this.lastNode = graph.get(leafId)
    }
    return graph;
  }

  buildEdge = async (graph, node, edge) => {
    const nodeId = this.nodeId(node.name)
    const edgeId = this.nodeId(edge.name)

    if (this.scanedDir.has(nodeId + edgeId) || this.scanedDir.has(edgeId + nodeId))
      return graph;

    //const nodeEdgeIfPointInRange = await this.isPointCanConnect(node, edge )
    //console.log ('-->nodeEdgeIfPointInRange', node.name, edge.name, nodeEdgeIfPointInRange );

    let branch = new Map
    branch = await this.buildLeafs(new Map, node, edge)
    graph = this.merge2Maps(graph, branch)

    if (!graph.has(nodeId)) {
      graph = this.addNodeToGraph(
          graph,
          nodeId,
          'point',
          'buildEdge',
          node.name,
          node.position,
          new Map
      )
    }

    if (!graph.has(edgeId)) {
      graph = this.addNodeToGraph(
          graph,
          edgeId,
          'point',
          'buildEdge',
          edge.name,
          edge.position,
          new Map
      )
    }

    //console.log(graph.get(nodeId).name, graph.get(edgeId).name)
    if (branch.size > 0) {
      const branchFL = this.getFirstLastNodeInBranch(branch)
      const leafPolyFirstIfPointInRange = await this.isPointCanConnect( graph.get(nodeId), graph.get(branchFL.firstNode.id) )
      if(leafPolyFirstIfPointInRange.isPointCanConnect)
      {
        graph = await this.connect2Nodes(graph, graph.get(nodeId), graph.get(branchFL.firstNode.id), 'f')
      }

      const leafPolyLastIfPointInRange = await this.isPointCanConnect(graph.get(branchFL.lastNode.id), graph.get(edgeId) )
      if(leafPolyLastIfPointInRange.isPointCanConnect) {
          graph = await this.connect2Nodes(graph, graph.get(edgeId), graph.get(branchFL.lastNode.id), 'l')
      }

      if(!leafPolyFirstIfPointInRange.isPointCanConnect || !leafPolyLastIfPointInRange.isPointCanConnect)
      {
        branch = new Map

        graph = await this.connect2Nodes(graph, graph.get(nodeId), graph.get(edgeId), 'buildEdge')

        branch.set(edgeId, graph.get(edgeId) )
        branch.set(nodeId, graph.get(nodeId) )

        graph = this.merge2Maps(graph, branch)
      }

    }
    else
    {
      branch = new Map

      graph = await this.connect2Nodes(graph, graph.get(nodeId), graph.get(edgeId), 'buildEdge')

       branch.set(edgeId, graph.get(edgeId) )
       branch.set(nodeId, graph.get(nodeId) )

       graph = this.merge2Maps(graph, branch)
    }

    this.lastBranch = branch
    this.lastNode = null
    return graph;
  }

  scanDir = async (graph, from, to) => {

    let fromOrigin = from.clone()
    const distanceX = to.position.z - from.position.z
    const stepDistance = 1
    for (let i = 0; i < distanceX; i++) {
      const pointPlus = parseInt(stepDistance * i)
      const newPosition = {
        x: fromOrigin.position.x,
        y: fromOrigin.position.y,
        z: fromOrigin.position.z + pointPlus,
      }

      /*if (to.position.x < newPosition.x)
        return graph;*/
      graph = await this.leaf(graph, newPosition, from, to);
    }
    return graph
  }

  buildLeafs = async (graph, node, edge) => {
    const nodeId = this.nodeId(node.name)

    const edgeId = this.nodeId(edge.name)

    if (this.scanedDir.has(nodeId + edgeId) || this.scanedDir.has(edgeId + nodeId))
      return graph;

    this.scanedDir.set(nodeId + edgeId, {})
    this.scanedDir.set(edgeId + nodeId, {})

    graph = await this.scanDir(graph, node, edge)
    return graph;
  }

  deleteEdge = (graph, pointA, pointB) => {
    graph.get(pointA.id).paths.delete(pointB.id)
    graph.get(pointB.id).paths.delete(pointA.id)

    return graph;
  }

  scanPoints = async () => {
    const polygons = this.experience.world.mapModel.polygons;

    const pointConnectors = new Map()
    /*for(const inPoint in connectors.connect)
      pointConnectors.set(inPoint, connectors.connect[inPoint])*/

    const points = this.getSortedPointsByDistances()

    let graph = new Map();
    let stepN = 1
    let i = 0;
    for (const pathPoint of points) {
      const node = pathPoint[0]
      const nodeId = this.nodeId(node.name)

      let nn = 0;
      for (const pathEdge of points) {
        const edge = pathEdge[0]

        if (node.id === edge.id)
          continue;

        if (pointConnectors.has(node.name) && !_.includes(pointConnectors.get(node.name), edge.name))
          continue;

        if (pointConnectors.has(edge.name) && !_.includes(pointConnectors.get(edge.name), node.name)) {
          continue;
        }

        const distance = await this.getDistance(node, edge);
        let cross = await this.getCrossPolygons(node, edge);

        const direction = new THREE.Vector3().copy(node.position).sub(edge.position);
        const angelPoint = new THREE.Vector3(0, 0, 0)
        const angle = direction.angleTo(angelPoint);

        const ifPointInSamAxis = this.ifPointInRange(node, edge)

        const crossPoints = await this.getCrossPoints(node, edge)

        //const isPointCanConnect = await this.isPointCanConnect(node, edge)


        if (crossPoints !== 0 && crossPoints[0]['object']['name'] !== edge.name || !ifPointInSamAxis)
          continue;


        if ((cross == 0 || (cross.length > 0 && cross[0] && cross[0]['distance'] > distance)) && ifPointInSamAxis) {

          graph = await this.buildEdge(graph, node, edge)

          if(nn > 0)
          {
            //return this.graphToArr(graph);
          }
          nn++;
        }

      }

      i++;
    }
    return this.graphToArr(graph);
  }

  buildNodes(){
    return this.scanPoints()
  }

  getPath = (polygonFrom, polygonTo) => {
    if (!this.graph)
      return;

    const start = this.graph.get(polygonFrom.name)
    const end = this.graph.get(polygonTo.name)

    return this.dijkstra(start, end, this.graph)
  }

  forceConnect = () => {

    const pointConnectors = new Map()
    pointConnectors.set('point026', ['point007', 'point006'])
    pointConnectors.set('point007', ['point026', 'point005'])

    pointConnectors.set('point006', ['point026'])

    pointConnectors.forEach((conNode, conKey) => {
      for (let m = 0; m < conNode.length; m++) {

      }
    })
  }
}
