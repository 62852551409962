export default {
    initial: {
        bgColor: '#add6ff',
        cameraPosition: [0.10730207845758873, 54.35682326232958, 25.52790828386542],
        cameraPositionMobile: [0 ,  70.13078643826685 ,  36.50468074116746],

        camerPositionss: [0.05808853365734408, 43.13762520346719, 23.037852481274935],
        camerPositionMobiless: [-0.30695182984993147, 88.41896858211739, 47.30802087594317]
    },
    'pathOptions': {
        'color': 0x3AB5FE,
        'arrowColor': 0xFFFFFF,
        'raduis': 2.2,
        'animateDrawing': 300,
        'arrowSpeed': 200,
        'arrowSpacing': 10,
        displayArrowsOnPath: true
    },
    'polygon': {
        clickedColor: 0xEE4813
    }


}
