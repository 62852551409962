

import testLocations from '../const/locations.json'
import graph from '../const/graph.json'

export default class ApiStore
{
    constructor()
    {

    }

    get = () => {

    }

    post = () => {

    }

    getVenue = () => {
        return new Promise( (resolve) => setTimeout(()=>{
            return resolve(testLocations);
        },2200) )
    }

    getGraph = () => {
        return new Promise( (resolve) => setTimeout(()=>{
            const fGraph = []
            graph.forEach((node)=>{
                fGraph.push(node)
            })
            return resolve(fGraph);
        },2200) )
    }

}
