export default [
    /*{
        name: 'mapModel',
        type: 'glpfDraco',
        path: 'models/map/exhibition_v4.glb'
    },*/
    /*{
        name: 'mapModel',
        type: 'gltf',
        path: 'models/map/exhibition_v4.gltf'
    },*/
    /*{
        name: 'mapModel',
        type: 'gltf',
        path: 'models/mymodel/f1e1.gltf'
    },*/
    {
        name: 'mapModel',
        type: 'gltf',
        path: 'models/mbf/v1.gltf'
    },
    /*{
        name: 'mapModel',
        type: 'gltf',
        path: 'models/mbf/mbfMap.gltf'
    },*/
    /*{
        name: 'coffeeModel',
        type: 'gltf',
        path: 'models/coffee/085-coffee.gltf'
    }*/
]
