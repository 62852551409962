import * as dat from 'lil-gui'

import Experience from '../Experience.js'

export default class Screen
{
  constructor()
  {
    const url = window.location
    const route = url.pathname
    const params = new URLSearchParams(url.search.toString())

    /*for (const [key, value] of params.entries()) {
      console.log ('-->key, value', key, value );
    }*/


    this.route = route
    this.query = params
    
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.camera = this.experience.camera.instance
    this.debug = this.experience.debug

  }
}
