import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
//import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import EventEmitter from './EventEmitter.js'
import _ from 'lodash'
export default class Resources extends EventEmitter {
    constructor(sources) {
        super()

        this.sources = sources

        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0

        this.setLoaders()
        this.startLoading()
    }

    setLoaders() {
        this.loaders = {}

        /*const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco/')

        const gltfLoader = new GLTFLoader()
        gltfLoader.setDRACOLoader(dracoLoader)

        this.loaders.gltfDracoLoader = gltfLoader*/

        const gltfLoaderOrig = new GLTFLoader()
        this.loaders.gltfLoader = gltfLoaderOrig
    }

    startLoading() {


        // Load each source
        for (const source of this.sources) {
            if (source.type === 'glpfDraco') {
                this.loaders.gltfDracoLoader.load(
                    source.path,
                    (file) => {
                        this.sourceLoaded(source, file)
                    }
                )
            } else if (source.type === 'gltf') {
                const textureLoader = new THREE.TextureLoader()

                this.loaders.gltfLoader.load(
                    source.path,
                    (file) => {
                        //const bakedTexture = textureLoader.load('./models/mbf/Material-v2.jpg')
                        //bakedTexture.flipY = false
                        //const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })

                        //const redMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, roughness: 1 })

                        file.scene.traverse((child) => {
                            child['userData']['originMatrial'] = _.clone(child.material)
                                //child.material = bakedMaterial
                            child.castShadow = true
                            child.receiveShadow = true

                            if (child.name === 'floor') {

                            } else {
                                //child.material = redMaterial;
                                //console.log ('-->child.name', child.name );
                            }
                        })

                        this.sourceLoaded(source, file)
                    }
                )
            }
        }
    }

    sourceLoaded(source, file) {
        this.items[source.name] = file

        this.loaded++

            if (this.loaded === this.toLoad) {
                this.trigger('ready')
                const eleLoading = document.getElementsByClassName('loading-container')[0];
                setTimeout(() => {
                    eleLoading.className = 'hide';
                }, 100)

            }
    }
}
