import * as THREE from 'three';
import * as dat from 'lil-gui'
import * as TWEEN from 'tween.js';
import Experience from '../Experience.js'
import Screen from './Screen'
import '../../scss/styles.scss'

import _ from 'lodash'
import * as bootstrap from 'bootstrap'

import Alert from 'bootstrap/js/dist/alert';

// or, specify which plugins you need:
import { Tooltip, Toast, Popover } from 'bootstrap';
import redPulses from "../Effects/RedPulses.js";


export default class ReserveBoothScreen extends Screen {
    constructor() {
        super()

        this.active = this.route === '/reserveBooth'
        if (!this.active)
            return;

        this.scene = this.experience.scene;
        this.collisionEngine = this.experience.collisionEngine

        this.experience.emit.on('onPolygonClicked', this.onPolygonClicked)
        this.experience.emit.on('onPolygonDeselect', this.onPolygonDeselect)

        this.experience.emit.on('modelMapLoaded', this._onModelMapLoaded)
        this.experience.emit.on('controlUpdateStart', this._controlUpdateStart)
        this.experience.emit.on('controlUpdateEnd', this._controlUpdateEnd)


        this.canSelectPolygon = false
        this.initial()

        this.renderInfoBox()

        this.testRenderRedFlag()

        this.redPulses = new redPulses();
    }

    initial = () => {
        this.reservePolygons = new Map;
        this.selectedColor = 0x079A34;
        this.availablePolygonColor = 0xDC2E3D;
        this.availableBooths = new Map;

        const self = this
        window.addEventListener('message', function(e) {
            const iframMsg = JSON.parse(e.data);
            if (iframMsg.channel === 'set_available_booths') {
                self.availablBoothsIds = iframMsg.availableBooths;

                setTimeout(() => {
                    self.onSetAvailableBooths()
                    if(iframMsg.publisher_name)
                    {
                        self.publisherName = iframMsg.publisher_name
                    } else {
                        self.publisherName =  'تم الاختيار'
                    }
                }, 100)
            }

            if (iframMsg.channel === 'set_selected_booth') {
                setTimeout(() => {
                    const polygons = this.experience.world.mapModel.polygons;
                    polygons.forEach((node) => {
                        if (iframMsg.selectedBooth === node.name) {
                            this.canSelectPolygon = true;
                            self.onPolygonClicked([node], true)
                        }
                    })

                }, 100)
            }

        });
    }

    onSetAvailableBooths = () => {
        const polygons = this.experience.world.mapModel.polygons;
        polygons.forEach((node) => {
            if (_.includes(this.availablBoothsIds, node.name)) {
                //this.availablePolygons.set()
                node.material = new THREE.MeshPhysicalMaterial({
                    color: 0xC8C8C8,
                    roughness: 1
                });
                this.availableBooths.set(node.name, node)
                this.redPulses.add(node)
            }
        })
        this.experience.emit.trigger('set_available_booths',[this.availableBooths])
    }

    _onModelMapLoaded = () => {
        window.parent.postMessage(JSON.stringify({
            channel: 'map_load_done'
        }), '*');
    }

    _controlUpdateStart = () => {
        this.canSelectPolygon = false
    }

    _controlUpdateEnd = (val) => {
        if (val < 10)
            this.canSelectPolygon = true

        setTimeout(() => {
            this.canSelectPolygon = true
        }, 20)
    }

    onPolygonClicked = (polygon, canSelectPolygon = false) => {


        /*if (!this.canSelectPolygon || !canSelectPolygon)
            return;*/

        polygon = polygon[0]


        console.log('polygonxx', polygon, this.canSelectPolygon)

        const availableToReserve = this.availableBooths

        if (!this.availableBooths.has(polygon.name))
            return;

        if (this.selectedPolygon === polygon.uuid) {
            this.onPolygonDeselect()
            return;
        }
        this.onPolygonDeselect()


        this.redPulses.removeAllRings()

        polygon.material = new THREE.MeshStandardMaterial({
            color: this.selectedColor,
            roughness: 1
        });
        this.collisionEngine.addMarkerPoint(polygon, this.publisherName)

        window.parent.postMessage(JSON.stringify({
            channel: 'set_polygon',
            polygon_name: polygon.name
        }), '*');

        this.selectedPolygon = polygon.uuid
    }

    onPolygonDeselect = (polygon) => {
        //polygon = polygon[0]
        this.collisionEngine.removeAllMarkerPoint()
        this.selectedPolygon = null

        window.parent.postMessage(JSON.stringify({
            channel: 'set_polygon',
            polygon_name: null
        }), '*');

        this.availableBooths.forEach((node) => {
            if (node['userData']['originMatrial'] && node['userData']['originMatrial']['color']) {
                node.material = new THREE.MeshPhysicalMaterial({
                    color: 0xC8C8C8,
                    roughness: 1
                });
            }
        });
    }

    renderInfoBox = () => {
        const ref1Svg = `<svg id="e1hd6iQAr071" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 300" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><ellipse rx="30" ry="30" transform="matrix(1.219472 0 0 1.218512 150 150)" stroke="#000"/><ellipse rx="30" ry="30" transform="translate(150 150)" fill="#E49997"/></svg>`
        const ref2Svg = `<svg id="e1hd6iQAr071" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 300" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><ellipse rx="30" ry="30" transform="matrix(1.219472 0 0 1.218512 150 150)" stroke="#000"/><ellipse rx="30" ry="30" transform="translate(150 150)" fill="#079A34"/></svg>`
        const ref3Svg = `<svg id="e1hd6iQAr071" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 300" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><ellipse rx="30" ry="30" transform="matrix(1.219472 0 0 1.218512 150 150)" stroke="#000"/><ellipse rx="30" ry="30" transform="translate(150 150)" fill="#C0C0C0"/></svg>`
        const rootElement = document.getElementById('root');
        const htmlContent = `
        <div class="alert alert-dark" role="alert" style="padding: 0px 0px 0px 0px;backdrop-filter: blur(8px);background: border-box;background-color: rgb(255 255 255 / 0.3);" dir="rtl">
          <div class="row">
            <div class="col">
              <div class="row">
                  <div class="col-3">
                      <div style="width: 60px; height: 60px">${ref3Svg}</div>
                  </div>
                  <div class="col-8 text-center align-middle" style="margin-top: 16px;">
<b >متاح للحجز</b>
                  </div>
              </div>
            </div>
            <div class="col">
                <div class="row" style="margin-right: -51px;">
                    <div class="col-3">
                      <div style="width: 60px; height: 60px">${ref2Svg}</div>
                    </div>
                    <div class="col-9 " style="margin-top: 16px;">
                       <b>تم اختياره للحجز</b>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row" style="margin-right: -51px;">
                    <div class="col-3">
                      <div style="width: 60px; height: 60px">${ref1Svg}</div>
                    </div>
                    <div class="col-9 " style="margin-top: 16px;">
                       <b>محجوز</b>
                    </div>
                </div>
            </div>
          </div>
         </div>
        </div>
    `
        const tempElement = document.createElement('div');
        //tempElement.className = 'py-12 px-12 mx-auto'
        tempElement.innerHTML = htmlContent;
        tempElement.style.position = 'absolute'
            //tempElement.style.width = '441px'
            //tempElement.style.height = '100px'
            //tempElement.style.margin = '20px 0 0 20px'
        rootElement.appendChild(tempElement);

    }

    //test
    testRenderRedFlag = () => {

        //setTimeout(()=>{
            //this.polygons = this.experience.world.mapModel.polygons;
            //console.log('this.polygons', this.polygons)
            //this.polygons.forEach((row)=>{
                // console.log('row', row)
                // row.material = new THREE.MeshPhysicalMaterial({
                //     color: 0xC8C8C8,
                //     roughness: 1
                // });
                //console.log('addd')
                 //this.redPulses.add(row)
            //})
        //}, 2000)


        // this.experience.emit.on('set_available_booths', (resres)=>{
        //     resres[0].forEach((row)=>{
        //        // console.log('row', row)
        //         //this.redPulses.add(row)
        //     })
        // })
    }


}
