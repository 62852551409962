import * as THREE from 'three';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

import { v4 as uuidv4 } from 'uuid';

import Experience from '../Experience.js'
import _ from 'lodash'

import HtmlHelper from './HtmlHelper'

export default class CollisionEngine extends HtmlHelper {
    constructor() {
        super()

        if (this.debug.active) {
            this.runDebug()
        }

        //this.setCamerPosition()
        //this.run()
    }

    run = () => {
        this.experience.emit.on('onLoadVenue', (venue) => {
            this.polygons = this.experience.world.mapModel.polygons;
            this.onLoadVenue(venue)
        })
    }

    onLoadVenue = (venue) => {
        const polygons = Array.from(this.polygons, ([name, value]) => (value));

        let n = 0;
        for (n = 0; n < polygons.length; n++) {
            let polygon = polygons[n]
            if (venue[n] && venue[n]['name']) {
                this.addTitlePointToVenue(polygon, venue[n])
            }
        }

        //this.experience.emit.trigger('addTitlePoint', [polygon, venue])

        this.fireCollisionTriggers()

    }

    addHtmlTo3d = (wrapper, polygon) => {
        const htmlObj = new CSS2DObject(wrapper);
        htmlObj.position.set(0, 0.18, 0);
        htmlObj.center.set(0, 1);
        polygon.add(htmlObj);
        htmlObj.layers.set(0);
        return htmlObj;
    }

    addTitlePointToVenue = (polygon, venue) => {
        let wrapper = this.pointTitle(polygon, venue)
        let html = this.addHtmlTo3d(wrapper, polygon)
    }

    opacityPoints(opacity = 0.2) {

        const sheet = new CSSStyleSheet();
        sheet.insertRule(`
      .collision_ele {
        opacity: ${opacity};
        transform: scale(1.2);
      }
    `);
        document.adoptedStyleSheets = [sheet];
    }

    opacityPoints(opacity = 0.2) {
        const sheet = new CSSStyleSheet();
        sheet.insertRule(`
      .collision_ele {
        opacity: ${opacity};
        //transform: scale(1.2);
      }
    `);
        document.adoptedStyleSheets = [sheet];
    }

    setPointOpacity1 = () => {
        const sheet = new CSSStyleSheet();
        sheet.insertRule(`
      .collision_ele {
      opacity: 1;
        //animation: fade-in 1s ease forwards;
      }
    `);
        document.adoptedStyleSheets = [sheet];
    }

    setPointOpacity0 = () => {
        const sheet = new CSSStyleSheet();
        sheet.insertRule(`
      .collision_ele {
      opacity: 0.2;
        //animation: fade-out 1s ease forwards;
      }
    `);
        document.adoptedStyleSheets = [sheet];
    }

    collisionPoints() {
        const markerEles = new Map()
        const textElements = document.getElementsByClassName('maxmap-tooltip')
        const elements = document.getElementsByClassName('collision_ele_hide');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('collision_ele_hide');
        }

        for (let i = 0; i < textElements.length; i++) {
            const polygonName = textElements[i].getAttribute('data-polygon-key')
            const polygon = this.polygons.get(polygonName);

            const text = textElements[i]
            const textRect = text.getBoundingClientRect();

            //console.log ('-->textRect', textRect );

            const textRectLeft = textRect.left + 10
            const textRectRight = textRect.right - 0
            const textRectTop = textRect.top - 10
            const textRectBottom = textRect.bottom + 10

            for (let j = i + 1; j < textElements.length; j++) {
                const otherText = textElements[j];
                const childElement = textElements[j].querySelector('div');

                if (otherText === text) {
                    continue;
                }

                /* console.log ('-->Marker', text.getElementsByClassName('maxmap-tooltip_marker') );
                 if(text.getElementsByClassName('maxmap-tooltip_marker'))
                   continue;*/


                if (childElement && childElement.classList.contains('collision_ele_hide')) {
                    continue;
                }

                const otherRect = otherText.getBoundingClientRect();
                if (
                    textRectRight > otherRect.left &&
                    textRectLeft < otherRect.right &&
                    textRectBottom > otherRect.top &&
                    textRectTop < otherRect.bottom
                ) {
                    if (text.querySelectorAll('.collision_ele_marker').length > 0) {
                        const markerId = text.getAttribute('id').split('marker_-')
                        markerEles.set(markerId[1], text)
                        childElement.classList.add('collision_ele_hide')
                        continue;
                    }
                    text.classList.add('collision_ele_hide')
                }

            }
        }

        markerEles.forEach((m, k) => {
            let ele = document.getElementById('point_-' + k)
            if (ele && ele.querySelector('.collision_ele_hide'))
                ele.querySelector('.collision_ele_hide').style.display = 'none'
        })

    }

    onCameraMoveStart = () => {
        this.setPointOpacity0()
    }

    onCameraMoving = () => {

    }

    onCameraMoveEnd = () => {
        setTimeout(() => {
            setTimeout(() => {
                this.collisionPoints()
                this.setPointOpacity1()
            }, 20)
        }, 10)
    }

    fireCollisionTriggers = () => {
        var camera = this.camera.instance;

        setTimeout(() => {
            this.setPointOpacity0()
            this.collisionPoints()
            const sheet = new CSSStyleSheet();
            sheet.insertRule(`
              .collision_ele {
                animation: fade-in 1s ease forwards;
              }
            `);
            document.adoptedStyleSheets = [sheet];

            this.collisionPoints()
        }, 420)

        let needUpdate = false

        this.experience.emit.on('camera-move-start', () => {
            setTimeout(() => {
                //needUpdate = false
                this.onCameraMoveStart(this)
            }, 120)
        })
        this.experience.emit.on('camera-move-ing', (arg) => {
            setTimeout(() => {
                //needUpdate = false
            }, 100)
        })
        this.experience.emit.on('camera-move-end', () => {
            setTimeout(() => {
                needUpdate = true
            }, 100)
        })

        /*this.experience.emit.on('camera-move-zoom' ,  ()=>{
          setTimeout(()=>{
            needUpdate = true
          }, 300)
        })*/

        /*camera.addEventListener('zoom', ()=>{
          const camera = event.target.camera;
          console.log(camera.projectionMatrix.elements);

        });*/

        setInterval(() => {

            if (needUpdate === false)
                return

            needUpdate = false

            //this.onCameraMoveEnd()

        }, 100)
    }

    setCamerPosition = () => {
        var camera = this.camera.instance;
        var newView = new THREE.Vector3(10, 0, 0);
        camera.position.set(0, 200, -130);
        camera.lookAt(newView);
    }

    addMarkerPoint = (polygon, title) => {
        const wrapper = this.markerTitle(polygon, title)
        const html = this.addHtmlTo3d(wrapper, polygon)
        return html
    }

    runDebug = () => {

    }

    update = () => {

    }
}
