import * as THREE from 'three'
import Experience from '../Experience.js'
import _ from 'lodash';
import { Vector3 } from 'three';
import * as TWEEN from 'tween.js';
import mapOptions from '../../const/mapOptions'
import { InteractionManager } from 'three.interactive';


export default class MapModel {
  constructor(){
    this.experience = new Experience()
    this.collisionEngine = this.experience.collisionEngine
    this.scene = this.experience.scene
    this.camera = this.experience.camera.instance
    this.resource = this.experience.resources.items.mapModel
    this.debug = this.experience.debug

    this.interactionManager = new InteractionManager(
        this.experience.renderer.instance,
        this.camera,
        this.experience.canvas
    );


    this.polygons = new Map()

    this.setModel()
    this.setModelObjects()

    this.loadVenue()

    this.clickedObj = null
    this.experience.emit.on('onSceneClick', this.onPolygonClick)

    this.experience.emit.on('controlUpdate', this.controlUpdate)
    this.experience.emit.on('controlUpdateEnd', this.controlUpdateEnd)

    if(this.debug.active)
      console.log('Render Info::', this.experience.renderer.instance.info)
  }

  setModel(){
    const self = this
    this.model = this.resource.scene
    this.model.scale.set(1.0, 1.0, 1.0)

    if (this.debug.active) {
      const pathParams = {
        showModal: true,
      }

      this.debugPathFolder = this.debug.ui.addFolder('MapModel')
      this.debugPathFolder.add(pathParams, 'showModal', 'boolean')
          .onChange((val) => {
            if (val === true)
              this.scene.add(this.model)
            else
              this.scene.remove(this.model)
          });
    }

    //intial animation
    let initialZoom = 0;
    let targetZoom = 50;
    this.scene.add(this.model)
    /*new TWEEN.Tween({ zoom: initialZoom })
        .to({ zoom: targetZoom }, 1000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onStart(() => {
          this.scene.add(this.model)
        })
        .onUpdate((object) => {
          this.camera.zoom = object;
          this.camera.updateProjectionMatrix();
        }).onComplete(()=>{

        })
        .start();*/


    /*const coffeeModel = this.experience.resources.items.coffeeModel.scene
    coffeeModel.scale.set(0.5, 1, 0.5)
    coffeeModel.position.set(-2, -0.390, - 0.9)
    this.scene.add(coffeeModel)*/
  }

  setModelObjects(){
    const polygons = new Map();
    const points = new Map();
    const modelObjects = new Map();
    const modelObjectsById = new Map();

//    console.log ('-->this.resource.scene', this.resource.scene );
    this.resource.scene['children'].forEach((node, i) => {

      //console.log ('-->nodeIn.name', node.name );
      if (_.includes(node.name, 'polygon')) {
        //node['userData']['originMatrial'] = node.material.clone()

        /*node.material = new THREE.MeshStandardMaterial({
            color: 0xC8C8C8,
            roughness: 1
          });*/


        this.interactionManager.add(node);
        node.addEventListener('click', (event) => {
          event.stopPropagation()
          //console.log('xxxx')
          //onPolygonDeselect
          //this.experience.emit.trigger('onPolygonDeselect')
          this.experience.emit.trigger('onPolygonClicked', [node])

        });

        polygons.set(node.name, node);
      }

      if (_.includes(node.name, 'floor')) {

        node.addEventListener('click', (event) => {
          console.log ('-->floor' );
          this.experience.emit.trigger('onPolygonDeselect')
        });
      }

      if (node instanceof THREE.Object3D) {
        node.children.forEach((nodeIn, i) => {

          if (_.includes(nodeIn.name, 'polygon')) {
            polygons.set(nodeIn.name, nodeIn);
          }
          if (_.includes(nodeIn.name, 'polygon')) {
            polygons.set(nodeIn.name, nodeIn);
          }
        });

        if (_.includes(node.name, 'polygon')) {
          node['userData']['originMatrial'] = node.material


          /*node.material = new THREE.MeshStandardMaterial({
            color: 0xC8C8C8,
            roughness: 1
          });*/

          polygons.set(node.name, node);

        }

        /*if (_.includes(node.name, 'point')) {
          node.visible = false
          points.set(node.name, node);
        }*/

        /*if (_.includes(node.name, 'polygon_click')) {
          node.visible = false
        }*/

        modelObjects.set(node.name, node)
        modelObjectsById.set(node.id, node)
      }
    })
    this.polygons = polygons
    this.points = points
    this.modelObjects = modelObjects
    this.modelObjectsById = modelObjectsById

    setTimeout(()=>{
      this.experience.emit.trigger('modelMapLoaded')
    }, 10)
  }

  loadVenue = () => {
    return this.experience.store.apiStore.getVenue().then(res => {
      this.experience.emit.trigger('onLoadVenue', res)
    })
  }

  controlUpdate = () => {
    this.canSelectPolygon = false
  }

  controlUpdateEnd = (dampingVal) => {
    //console.log ('-->dampingVal', dampingVal );
    if(dampingVal <= 30)
      this.canSelectPolygon = true
  }

  onPolygonClick = (intersects) => {
    /*if(!this.canSelectPolygon)
      return;*/

    console.log ('-->intersects', intersects );
    if (_.includes(intersects[0].object.name, 'polygon')) {
      const polygon = intersects[0].object

      if (this.clickedObj) {
        this.clickedObj.material = this.clickedObj['userData']['originMatrial'];
        this.clickedObj.userData['is_clicked'] = false;
        this.experience.emit.trigger('onPolygonDeselect', [this.clickedObj])
      }

      polygon.userData['is_clicked'] = true;

      this.clickedObj = intersects[0].object

      this.experience.emit.trigger('onPolygonClicked', [polygon])
    } else {

      if (this.clickedObj) {
        //this.clickedObj.material = this.clickedObj['userData']['originMatrial'];
        //this.clickedObj.userData['is_clicked'] = false;
        this.experience.emit.trigger('onNotPolygonDeselect', [intersects[0].object])
      }
    }
    //this.clickedObj = intersects[0].object
  }

  update(){
    this.collisionEngine.update()
    this.interactionManager.update();

  }
}
