import * as dat from 'lil-gui'

import Stats from 'stats.js'



export default class Debug
{
    constructor()
    {
        const url = window.location
        const route = url.pathname
        const params = new URLSearchParams(url.search.toString())

        this.active = parseInt(params.get('debug'))

        if(this.active)
        {
            console.log ('-->debug', this.active );
            this.ui = new dat.GUI()
        }
    }
}
