import * as THREE from 'three'
import Renderer from './Renderer.js'
import Camera from './Camera.js'
import Debug from './Utils/Debug.js'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import EventEmitter from '../Experience/Utils/EventEmitter'
import sources from './sources.js'
import Store from '../Stores/index'
import Path from './Paths/Path'
import CollisionEngine from './CollisionEngine/CollisionEngine'

import PolygonConfigScreen from '../Experience/Screens/PolygonConfigScreen'
import ReserveBoothScreen from '../Experience/Screens/ReserveBoothScreen'
import TouchControls from './Controls/TouchControls.js'

let instance = null

export default class Experience {
    constructor(_canvas) {
        //super()
        // Singleton
        if (instance) {
            return instance
        }
        instance = this

        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas

        // Setup
        this.debug = new Debug()
        this.store = Store
        this.emit = new EventEmitter()

        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.camera = new Camera()
        //this.touchControls = new TouchControls(this.camera)
        this.renderer = new Renderer()
        this.world = new World()
        this.path = new Path();
        this.collisionEngine = new CollisionEngine();
        //screenIntial
        this.polygonConfigScreen = new PolygonConfigScreen();
        this.reserveBoothScreen = new ReserveBoothScreen();


        // Resize event
        this.sizes.on('resize', () => {
            this.resize()
        })

        // Time tick event
        this.time.on('tick', () => {
            this.update()
        })
    }

    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    update() {
        this.camera.update()
        this.world.update()
        this.renderer.update()
        //this.touchControls.update()
    }

    destroy() {
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) => {
            // Test if it's a mesh
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose()

                // Loop through the material properties
                for (const key in child.material) {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if (value && typeof value.dispose === 'function') {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if (this.debug.active)
            this.debug.ui.destroy()
    }

}
