import Experience from '../Experience.js'
import CryptoJS from 'crypto-js';
import * as THREE from 'three';
import * as TWEEN from 'tween.js';

import PathManger from './PathManger'

let instance = null

export default class PathHelper {

  constructor(){
    this.experience = new Experience()
    this.pathHelper = this.experience.PathHelper
    this.scene = this.experience.scene

    this.raycastVal = new Map;
    this.lineDrawed = new Map;

    this.pathLineGroup = new Map;
    this.pathArrowGroup = new Map;
    this.animatedArrowTween = new Map;

  }

  xyzFormat = (point) => {

    if (point && !point['position']) {
      let pointX = {}
      pointX['position'] = {
        x: point.x,
        y: point.y,
        z: point.z,
      }

      return pointX
    }

    return point
  }

  getDistance = (pointA, pointB) => {
    let pointAP = this.xyzFormat(pointA)
    let pointBP = this.xyzFormat(pointB)
    const position1 = new THREE.Vector3(pointAP.position.x, pointAP.position.y, pointAP.position.z);
    const position2 = new THREE.Vector3(pointBP.position.x, pointBP.position.y, pointBP.position.z);
    return position1.distanceTo(position2);
  }

  getMinDistance = (pointA, pointB) => {
    let pointAP = this.xyzFormat(pointA)
    let pointBP = this.xyzFormat(pointB)

    const box1 = new THREE.Box3().setFromObject(pointA);
    const box2 = new THREE.Box3().setFromObject(pointB);

    return Math.min(
        box1.distanceToPoint(box2.min),
        box1.distanceToPoint(box2.max),
        box2.distanceToPoint(box1.min),
        box2.distanceToPoint(box1.max)
    );
  }

  getHalfway = (from, to) => {
    const halfwayPoint = new THREE.Vector3().lerpVectors(from.position, to.position, 0.5);
    return { minDistance, halfwayPoint }
  }


  drawPoint(point, color = '0xff000f', width = 1, height = 40){
    point = this.xyzFormat(point)

    const geometryx = new THREE.CylinderGeometry(width, width, height, 30);
    const materialx = new THREE.MeshBasicMaterial({ color: color });
    const cylinder1 = new THREE.Mesh(geometryx, materialx);
    cylinder1.position.set(point.position.x, point.position.y, point.position.z)
    this.scene.add(cylinder1);
  }

  drawDashs(from, to, color = 'blud'){
    let fromOrigin = from.clone()
    const distanceX = to.position.x - from.position.x
    const stepDistance = 5
    for (let i = 0; i < distanceX; i++) {

      const pointPlus = parseInt(stepDistance * i)
      const newPosition = {
        x: fromOrigin.position.x + pointPlus,
        y: fromOrigin.position.y,
        z: fromOrigin.position.z,
      }

      this.drawPoint(
          from,
          'blue',
          .1,
          30,
      )

      if (to.position.x < newPosition.x)
        return;
    }
  }

  addNodeToGraph = (graph, id, type, ref, name, position, paths = new Map) => {
    graph.set(id, {
      id: id,
      type: type,
      ref: ref,
      name: name,
      x: position.x,
      y: position.y,
      z: position.z,
      paths: paths
    })
    return graph;
  }

  connect2Nodes = async (graph, nodeA, nodeB, mark = '') => {
    const distance = this.getDistance(graph.get(nodeA.id), graph.get(nodeB.id))

    /*
        const cross = await this.isPointCanConnect(graph.get(nodeA.id), graph.get(nodeB.id))
        console.log ('-->cross', graph.get(nodeA.id).name, graph.get(nodeB.id).name, cross );

        if(!cross.isPointCanConnect && cross.polygonCross.length < 1)
        {
          return graph;
        }
    */
    //console.log ('-->leafId', nodeA.id, nodeA.name, nodeB.id, graph.get(nodeA.id) );


    graph.get(nodeA.id).paths.set(nodeB.id, {
      node: graph.get(nodeB.id),
      distance: distance,
      name: nodeB.name,
      mark: mark
    })
    graph.get(nodeB.id).paths.set(nodeA.id, {
      node: graph.get(nodeA.id),
      distance: distance,
      name: nodeA.name,
      mark: mark
    })
    return graph;
  }

  /*connectToPaths = (graph, nodeId, edgeId) => {
    graph.get(edgeId).paths.set(endNodeInBranch.id,{
      node: graph.get(endNodeInBranch.id),
      distance: this.getDistance(graph.get(edgeId), graph.get(endNodeInBranch.id))
    })

    graph.get(endNodeInBranch.id).paths.set(edgeId,{
      node: graph.get(edgeId),
      distance: this.getDistance(graph.get(edgeId), graph.get(endNodeInBranch.id))
    })
  }*/

  convertGraph2Array = (graph) => {
    let nodes = [];
    graph.forEach((vNode) => {
      let paths = [];
      vNode.paths.forEach((neighbor) => {
        paths.push(neighbor)
      })
      vNode.paths = paths
      nodes.push(vNode)
    })
    return nodes;
  }

  getCrossPolygons = (from, to) => {

    const polygons = this.experience.world.mapModel.polygons;

    let pointAP = this.xyzFormat(from)
    let pointBP = this.xyzFormat(to)

    const poly1 = pointAP
    const poly2 = pointBP

    const raycaster = new THREE.Raycaster()

    const rayOrigin = new THREE.Vector3(poly1.position.x, poly1.position.y, poly1.position.z)
    const rayDirection = new THREE.Vector3(poly2.position.x, poly2.position.y, poly2.position.z)

    const direction = new THREE.Vector3();
    direction.subVectors(rayDirection, rayOrigin).normalize();

    raycaster.set(rayOrigin, direction)

    const intersects = raycaster.intersectObjects(Array.from(polygons.values()));
    this.raycastVal.set(to.name, intersects)
    if (intersects.length > 0) {
      return intersects;
    } else {
      return 0;
    }
  }

  getCrossPoints = (from, to) => {
    const points = this.experience.world.mapModel.points;

    let pointAP = this.xyzFormat(from)
    let pointBP = this.xyzFormat(to)

    const poly1 = pointAP
    const poly2 = pointBP

    const raycaster = new THREE.Raycaster()

    const rayOrigin = new THREE.Vector3(poly1.position.x, poly1.position.y, poly1.position.z)
    const rayDirection = new THREE.Vector3(poly2.position.x, poly2.position.y, poly2.position.z)

    const direction = new THREE.Vector3();
    direction.subVectors(rayDirection, rayOrigin).normalize();

    raycaster.set(rayOrigin, direction)

    const intersects = raycaster.intersectObjects(Array.from(points.values()));
    this.raycastVal.set(to.name, intersects)
    if (intersects.length > 0) {
      return intersects;
    } else {
      return 0;
    }
  }

  getFirstLastNodeInBranch = (branch) => {
    if (!branch || branch.size < 1)
      return null

    let firstNode;
    let lastNode;
    for (const point of branch) {
      if (point[1]['type'] === 'point' && !firstNode) {
        firstNode = point[1]
      }

      if (point[1]['type'] === 'point') {
        lastNode = point[1]
      }
    }
    return { firstNode, lastNode }
  }

  measurePoint = async (pointA, pointB) => {
    const cross = await this.getCrossPolygons(pointA, pointB);
    setTimeout(async () => {
      return {
        distance: this.getDistance(pointA, pointB),
        cross: await this.getCrossPolygons(pointA, pointB),
      }
    }, 10)
    //this.errrrv()
  }

  isInFrontMe = (pointA, pointB) => {
    let pointAP = this.xyzFormat(pointA)
    let pointBP = this.xyzFormat(pointB)
    return pointBP.z > pointAP.z
  }

  merge2Maps = (map1, map2) => {
    const newMap = new Map;

    map1.forEach((val, k) => {
      newMap.set(k, val)
    })
    map2.forEach((val, k) => {
      if (newMap.has(k)) {
        map2.get(k).paths.forEach((path, p) => {
          newMap.get(k).paths.set(p, path)
        })
        //newMap.get(k).paths.set(np, npath)
      } else {
        newMap.set(k, val)
      }
    })


    return newMap;
  }

  id1 = (str) => {
    return CryptoJS.EvpKDF(str, 'key12wwwwkkkkey').toString();
  }

  //diff image in same x or y
  ifPointInRange = (pointA, pointB) => {
    let pointAP = this.xyzFormat(pointA)
    let pointBP = this.xyzFormat(pointB)

    const diffx = Math.round(pointAP.position.x - pointBP.position.x) === 0
    const diffz = Math.round(pointAP.position.z - pointBP.position.z) === 0

    if (diffx && !diffz)
      return true

    if (diffz && !diffx)
      return true

    return false
  }

  isCross = (objects, from, to) => {
    const points = objects;

    let pointAP = this.xyzFormat(from)
    let pointBP = this.xyzFormat(to)

    const poly1 = pointAP
    const poly2 = pointBP

    const raycaster = new THREE.Raycaster()

    const rayOrigin = new THREE.Vector3(poly1.position.x, poly1.position.y, poly1.position.z)
    const rayDirection = new THREE.Vector3(poly2.position.x, poly2.position.y, poly2.position.z)

    const key = poly1.position.x + '-' + poly1.position.y + '-' + poly1.position.z + '-' + poly2.position.x + '-' + poly2.position.y + '-' + poly2.position.z

    if (this.raycastVal.has(key))
      return this.raycastVal.get(key)

    let direction = new THREE.Vector3();
    direction.subVectors(rayDirection, rayOrigin).normalize();

    raycaster.set(rayOrigin, direction)

    const directionToL = new THREE.Vector3(rayDirection.x, rayDirection.y, rayDirection.z)
    const toLine = rayOrigin.clone().add(direction);

    //this.drawLine(rayOrigin, toLine, '#019EF4', 1, .3)

    const intersects = raycaster.intersectObjects(Array.from(objects.values()));

    //to didint repet intersect check
    //this.raycastVal.set(key, intersects)

    if (intersects.length > 0) {
      return intersects;
    } else {
      return [];
    }
  }

  getNearstPolygon(point){
    const position = this.xyzFormat(point)
    const polygons = this.experience.world.mapModel.polygons;
    const distances = new Map;
    for (const _polygon of polygons.values()) {
      const distance = this.getDistance(position, _polygon)
      distances.set(distance, _polygon)
    }
    const nearst = new Map([...distances].sort((a, b) => {
      return a[0] - b[0];
    }))

    return nearst;
  }

  isPointCanConnect = async (pointA, pointB) => {
    const pointAP = this.xyzFormat(pointA)
    const pointBP = this.xyzFormat(pointB)
    let isPointCanConnect = false
    let diffxy = false
    let why = 'd'

    const position1 = new THREE.Vector3(pointAP.position.x, pointAP.position.y, pointAP.position.z);
    const position2 = new THREE.Vector3(pointBP.position.x, pointBP.position.y, pointBP.position.z);

    {
      let direction = new THREE.Vector3();
      direction.subVectors(position2, position1).normalize();

      const directionToL = new THREE.Vector3(position2.x, position2.y, position2.z)
      const toLine = position1.clone().add(direction);

      if (direction.x === 0 && direction.y === 0 && direction.z < 1.1) {
        isPointCanConnect = true
        why = 'same-z'
      }

      //console.log ('-->direction111',isPointCanConnect, pointA.name, pointB.name, direction, parseInt(direction.z) );
      //this.drawLine(position1, toLine, '#ff9fff', 1, .3)
    }

    const diffx = Math.ceil(pointBP.position.x - pointAP.position.x) < 1
    const diffz = Math.ceil(pointBP.position.z - pointAP.position.z) < 1

    if (diffx && !diffz) {
      isPointCanConnect = true;
      diffxy = true
    }
    if (diffz && !diffx) {
      isPointCanConnect = true
      diffxy = true
    }

    const distance = position1.distanceTo(position2);

    const points = this.experience.world.mapModel.points;
    const polygons = this.experience.world.mapModel.polygons;

    const pointCross = await this.isCross(points, pointAP, pointBP)
    const polygonCross = await this.isCross(polygons, pointAP, pointBP)

    if (pointCross.length > 0 && pointCross[0]['distance'] < distance) {
      isPointCanConnect = false
      why = 'pt-c'

      const cross0 = pointCross[0]['object']
      if (cross0.position.x === position2.x && cross0.position.y === position2.y && cross0.position.z === position2.z) {
        isPointCanConnect = true
        why = 'd'
      }
      if (pointCross[0].point.x === position1.x && pointCross[0].point.y === position1.y && pointCross[0].point.z === position1.z) {
        isPointCanConnect = true
        why = 'd'
      }
    }

    if (polygonCross.length > 0 && polygonCross[0]['distance'] < distance) {
      isPointCanConnect = false
      why = 'py-c'

      if (polygonCross[0]['object'].position === pointBP.position) {
        isPointCanConnect = true
        why = 'd'
      }

    }

    return {
      isPointCanConnect,
      pointCross,
      polygonCross,
      distance,
      why,
      diffxy: diffz
    }
  }

  getSortedPointsByDistances = () => {
    const points = this.experience.world.mapModel.points;
    const distances = new Map;
    for (const _point of points.values()) {
      const distance = this.getDistance({
        x: 0,
        y: 1,
        z: 0,
      }, _point)
      distances.set(_point, distance)
    }
    const pointsSorted = new Map([...distances].sort((a, b) => {
      return a[1] - b[1];
    }))
    return pointsSorted
  }

  dijkstra(start, end, finalGraph){

    // Create a map of distances from the start node to each node in the graph
    const distances = new Map();
    const fullInfo = new Map();

    const graph = this.convertGraph2Array(finalGraph)

    for (const node of graph) {
      distances.set(node.id, Infinity);
    }
    distances.set(start.id, 0);

    // Create a set of unvisited nodes
    const unvisited = new Set(graph);

    // Create a map of previous nodes for each node in the graph
    const previousNodes = new Map();

    // Loop until all nodes have been visited
    let round = 0;
    while (unvisited.size > 0) {
      // Find the unvisited node with the smallest distance from the start node
      let currentNode = null;
      let smallestDistance = Infinity;
      for (const node of unvisited) {
        if (distances.get(node.id) < smallestDistance) {
          smallestDistance = distances.get(node.id);
          currentNode = node;
        }
      }

      // If we have reached the end node, we can return the shortest path
      if (currentNode === end) {
        const path = [];
        while (previousNodes.has(currentNode.id)) {
          path.push(currentNode);
          currentNode = previousNodes.get(currentNode.id);
        }
        path.push(start);
        return path.reverse();
      }

      if (currentNode === null) {
        console.log('-->no currentNode', round);
        return;
      }

      // Mark the current node as visited and remove it from the unvisited set
      unvisited.delete(currentNode);

      // Update the distances to neighboring nodes
      for (const neighbor of currentNode.paths) {

        if (neighbor.node.type === 'polygon' && neighbor.node.id !== start.id && end.id !== neighbor.node.id)
          continue;

        const distance = distances.get(currentNode.id) + neighbor.distance;
        if (distance < distances.get(neighbor.node.id)) {
          distances.set(neighbor.node.id, distance);
          previousNodes.set(neighbor.node.id, currentNode);
        }
      }

      round++;
    }

    // If we get here, there is no path between the start and end nodes
    return null;
  }

  drawLine = (pointA, pointB, color = 'red', plus = 1, len = .1) => {
    let from = this.xyzFormat(pointA)
    let to = this.xyzFormat(pointB)

    const position1 = new THREE.Vector3(from.position.x, from.position.y, from.position.z);
    const position2 = new THREE.Vector3(to.position.x, to.position.y, to.position.z);

    const direction = new THREE.Vector3().subVectors(position2, position1);

    let nposition1 = position1.clone()
    let nposition2 = position2.clone()
    if (direction.x > 0) {
      //nposition1.z = nposition1.z + 1.66
      //nposition2.x = position2.x + 1.63
    }

    if (direction.x < 0) {
      //nposition1.z = nposition1.z + 1.66
      //nposition2.x = position2.x - 1.64
    }

    if (direction.z > 0) {
      //nposition1.z = nposition1.z - 1.66
      //nposition2.z = position2.z + 1.66
    }

    const distance = nposition1.distanceTo(nposition2);
    const geometry = new THREE.CylinderGeometry(len, len, distance, 32);
    const material = new THREE.MeshBasicMaterial({ color: color });
    const cylinder = new THREE.Mesh(geometry, material);
    //cylinder.position.set(position1.x, position1.y, position1.z);

    cylinder.position.set(
        (position1.x + position2.x) / 2,
        ((position1.y + position2.y) / 2) + plus,
        (position1.z + position2.z) / 2
    );
    cylinder.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction.clone().normalize());
    const axis = new THREE.Vector3(0, 1, 0);
    const angle = Math.acos(direction.dot(axis) / direction.length());
    const rotation = new THREE.Euler(angle);
    cylinder.scale.set(1, 1, 1);

    this.lineDrawed.set(cylinder.uuid, cylinder)
    this.scene.add(cylinder);
  }

  drawLine2 = () => {
    const points = []
    points.push(new THREE.Vector3(-5, 0, 0))
    points.push(new THREE.Vector3(5, 0, 0))
    let geometry = new THREE.BufferGeometry().setFromPoints(points)
    let line = new THREE.Line(
        geometry,
        new THREE.LineBasicMaterial({
          linewidth: 10,
          color: 0x8888ff
        })
    )
    line.scale.set(2, 5, 5);
    this.scene.add(line)

  }

  heilightTestShortstPath(shortestPath, color = '#3BB5FE', plus = 1){
    let prevNode = {}
    for (let i = 0; i < shortestPath.length; i++) {
      let pointA = {}
      pointA['position'] = {
        x: shortestPath[i].x,
        y: 0,
        z: shortestPath[i].z
      }
      let pointB = {}
      if (shortestPath[i + 1]) {
        pointB['position'] = {
          x: shortestPath[i + 1].x,
          y: 0,
          z: shortestPath[i + 1].z
        }
        //const color = ('#') + Math.floor(Math.random()*16777215).toString(16);
        //this.drawPoint(pointA, color)
        //this.drawPoint(pointA, color)
        //this.drawPoint(pointB, color)
        this.drawLine(pointA, pointB, color, .5, 1)
      }

    }
  }

  heilightGraph = (graph) => {
    let i = 0;
    graph.forEach((node, key) => {
      /*if(i > 3)
        return
      console.log ('-->node', node );*/
      const pos = 1
      const color = ('#') + Math.floor(Math.random() * 16777215).toString(16);
      for (let i = 0; i < node.paths.length; i++) {
        this.drawLine(
            node,
            graph.get(node.paths[i]['node']['id']),
            'green',
            pos,
            0.1,
        )
      }
      i++;
    })
  }

  removeAllTestedPathsHeilight = () => {
    this.lineDrawed.forEach((line) => {
      this.scene.remove(line)
    })
  }

  graphToArr = (graph) => {
    //console.log ('-->graph', graph );
    let ic = 0;
    let arrGraph = []
    graph.forEach((node) => {
      let arrNode = []
      node.paths.forEach((edge) => {
        arrNode.push({
          node: {
            id: edge.node.id,
            name: edge.node.name,
            type: edge.node.type,
            position: {
              x: edge.node.x,
              y: edge.node.y,
              z: edge.node.z,
            }
          },
          distance: edge.distance
        })
      })

      node.paths = arrNode
      arrGraph.push(node)

      ic++;
    })
    return arrGraph;
  }

  resetPathLineGroup = () => {
    this.pathLineGroup.forEach((line) => {
      this.scene.remove(line)
    })
  }

  resetpathArrowGroup = () => {
    this.animatedArrowTween.forEach((twn)=>{
      twn.forEach((twntwn)=>{
        TWEEN.remove(twntwn);
      })
      TWEEN.remove(twn);
    })
    this.pathArrowGroup.forEach((arrow) => {
      this.scene.remove(arrow)
    })
  }

  drawPath = (path) => {

    this.resetPathLineGroup()
    this.resetpathArrowGroup()

    this.pathManger = new PathManger(path);

    this.pathManger.drawPath();
    this.pathLineGroup = this.pathManger.pathLineGroup

    this.pathManger.drawArrows();
    this.pathArrowGroup = this.pathManger.pathArrowGroup

    this.animatedArrowTween = this.pathManger.animatedArrowTween

    document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
  }

  handleVisibilityChange = () => {
    if (document.hidden) {

    } else {
      this.resetpathArrowGroup()
      this.pathManger.drawArrows();
      this.pathArrowGroup = this.pathManger.pathArrowGroup

      this.animatedArrowTween = this.pathManger.animatedArrowTween
    }
  }
}
